<template>
	<div class="position-relative">
		<img :src="imgPortada" class="img-background w-100" style="height: 350px;">
		<div class="position-absolute portada object-position-top text-white">
			<div class="h-100 d-flex align-items-center justify-content-center">
				<h1 class="playfairDisplay" v-text="title"></h1>
			</div>
		</div>
	</div>
	<div>
		<div class="text-white bg-secondary position-relative">
			<span class="position-absolute w-25" style="right: 0px; top: 0px; bottom: 0px;">
				<img :src="imgBanner1" class="img-fluid">
			</span>
			<div class="form-row justify-content-center align-items-center h-100 position-relative">
				<div class="col-lg-8 py-5">
					<h1 class="text-center playfairDisplay fw-bold mt-3 fs-1">Es una nueva forma de dar y recibir regalos.</h1>
					<p class="text-center fs-5 playfairDisplay mb-3">Cada uno de los momentos más especiales de la vida ahora serán más significativos ya que con la mesa de regalos digital OURGIFT podrás personalizarlos y hacerlos más tuyos totalmente gratis.</p>
				</div>
			</div>
		</div>
		<div class="container py-5">
			<h1 class="fw-bold text-center mb-3 playfairDisplay text-muted">¿CÓMO FUNCIONA?</h1>
			<div class="form-row justify-content-center align-items-stretch">
				<div class="col-lg-3">
					<div class="d-flex flex-column h-100">
						<div class="mb-5 text-center">
							<h4 class="playfairDisplay fw-bold text-muted">1. ABRE TU MESA</h4>
							<p>
								Accede a ourgift.com y abre tu mesa.  La apertura y el acceso no tienen ningún costo y no necesitas proporcionar documentación adicional.
							</p>
							<font-awesome-icon :icon="faLaptop" class="display-2 text-secondary" />
						</div>
						<div class="mb-5 text-center mt-auto">
							<h4 class="playfairDisplay fw-bold text-muted">2. SELECCIONA TUS REGALOS</h4>
							<p>
								Con el catálogo de productos de nuestros exclusivos proveedores, podrás seleccionar lo que más te guste y añadirlo a tu mesa. Encontrarás muchísimas opciones para que elijas las mejores, de acuerdo a tus necesidades y preferencias.
							</p>
							<font-awesome-icon :icon="faGifts" class="display-2 text-secondary"/>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<img :src="imgComoFunciona" class="img-fluid">
				</div>
				<div class="col-lg-3">
					<div class="d-flex flex-column h-100">
						<div class="mb-5 text-center">
							<h4 class="playfairDisplay fw-bold text-muted">3. COMPARTE CON TODOS</h4>
							<p>
								Utiliza el link personalizado a tu mesa de regalos digital para que tus invitados puedan acceder a ella y sorprenderte. Las compras son seguras y muy fáciles de hacer.
							</p>
							<font-awesome-icon :icon="faUsers" class="display-2 text-secondary" />
						</div>

						<div class="mb-5 text-center my-auto">
							<h4 class="playfairDisplay fw-bold text-muted">4. DISFRUTA DE TUS REGALOS</h4>
							<p>
								Recibe los regalos y envia agredecimientos online a tus invitados
							</p>
							<img :src="imgRegalos" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<img :src="imgBorder" class="img-fluid">
		</div>
		<div class="container py-5">
			<h1 class="text-center mb-5">VENTAJAS DE TU MESA DE REGALOS</h1>

			<div class="row">
				<div class="col-lg-6 mb-5">
					<div class="d-flex align-items-center">
						<img :src="imgVentajas1" class="img-fluid me-3">
						<div class="text-center px-3">
							<h5>Puedes abrirla y acceder donde quiera que estes</h5>
							<p class="text-muted">
								Con tu dispositivo móvil o computadora podrás revisar el estatus en tiempo real de tu mesa de regalos digital
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-6 mb-5">
					<div class="d-flex align-items-center">
						<img :src="imgVentajas2" class="img-fluid me-3">
						<div class="text-center px-3">
							<h5>Puedes personalizarla las veces que necesites</h5>
							<p class="text-muted">
								La selección de regalos la podrás realizar las veces que desees y así escoger los regalos que más desees tener.
							</p>
						</div>
					</div>	
				</div>

				<div class="col-lg-6 mb-5">
					<div class="d-flex align-items-center">
						<img :src="imgVentajas3" class="img-fluid me-3">
						<div class="text-center px-3">
							<h5>Fácil administración de tu mesa de regalos</h5>
							<p class="text-muted">
								Con tan solo algunos clicks podrás darte cuenta de quienes te han hecho un regalo y si quieres enviarle una nota personalizada de agradecimiento
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-6 mb-5">
					<div class="d-flex align-items-center">
						<img :src="imgVentajas4" class="img-fluid me-3">
						<div class="text-center px-3">
							<h5>Asistencia</h5>
							<p class="text-muted">
								Si necesitas ayuda o tienes dudas, siempre nos podrás contactar para poder atenderte y guiarte
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="text-white bg-info" :style="{backgroundImage: 'url('+ImgBanner2+')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}">
			<div class="container py-5 ">
				<p class="text-center fs-5 py-5">
					<font-awesome-icon :icon="faQuoteLeft" class="fa-2x"/> La mesa de regalos es un manera <strong>de compartir  al organizador del evento como los invitados</strong> y una parte esencial de un momento tan especial como una graduación, cumpleaños, boda, 15 años, evento de días festivos como el dia del padre o la madre y cualquier evento especial para compartir el momento y conocer a sus invitados.  Recuerda que mientras más amplia sea la selección, los invitados tendrán más opciones para escoger la que se acomode a su presupuesto. <font-awesome-icon :icon="faQuoteRight" class="fa-2x"/>
				</p>
			</div>
		</div>
	</div>

</template>
<script type="text/javascript">
	import imgHome5 from '@/assets/img/home/5.jpg'
	import imgBanner1 from '@/assets/img/backgrounds/banner1.png'
	import imgComoFunciona from '@/assets/img/backgrounds/banner3.png'
	import imgRegalos from '@/assets/img/backgrounds/regalos.png'
	import imgPortada from '@/assets/img/backgrounds/our-services.jpg'
	import imgBorder from '@/assets/img/border.jpg'

	import ImgBanner2 from '@/assets/img/como-funciona/banner2.png'
	import imgVentajas1 from '@/assets/img/como-funciona/ventajas1.png'
	import imgVentajas2 from '@/assets/img/como-funciona/ventajas2.jpg'
	import imgVentajas3 from '@/assets/img/como-funciona/ventajas3.jpg'
	import imgVentajas4 from '@/assets/img/como-funciona/ventajas4.jpg'
	
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faLaptop, faGifts, faUsers, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
	
	export default {
		components: {
			FontAwesomeIcon
		},
		data(){
			return {
				faLaptop,
				faGifts,
				faUsers,
				imgPortada,
				imgBanner1,
				ImgBanner2,
				imgComoFunciona,
				imgRegalos,
				imgBorder,
				imgHome5,

				imgVentajas1,
				imgVentajas2,
				imgVentajas3,
				imgVentajas4,

				faQuoteLeft,
				faQuoteRight
			}
		},
		computed: {
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},
		}
	}
</script>